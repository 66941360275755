import LogRocket from 'logrocket';

if ( process.env.NODE_ENV !== "development" ) {
  LogRocket.init('d6by9l/dougconecom');
}

if ( typeof window !== "undefined" ) {
  window.hcSiteKey = (process.env.NODE_ENV !== "development" ) ?
  '8584f4cc-c386-4933-b8af-3daccc1fdeab' :
  '10000000-ffff-ffff-ffff-000000000001'
}
